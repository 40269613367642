
// jQuery is made available in global scope
window.$ = require('jQuery')
window.jQuery = window.$
var barba = require('@barba/core');
var slick = require('slick-carousel')
var slickLightbox = require('./slicklightbox.js')
var css = require('@barba/css');
var Cookies = require('js-cookie');

$(document).ready(function() {

  setTimeout(function() { 

    $(".loader").removeClass('active');

  
  }, 3000);


  setTimeout(function() { 

    $('.mobile-menu').addClass("active");
    $('.mobile-trigger').addClass("active");
    $(".home__header").addClass("active");
    $(".menu-trigger").addClass("active");

  
  }, 2500);

  setTimeout(function() { 
    $(".home__header video").addClass('active');
  }, 3000);
});



$(document).ready(function() {


  $(".scroll-downs").click(function() {
    $("body, html").animate({scrollTop: $(window).height()/1.8}, "slow");
    return false;
  })

  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
      $(".scroll-downs").addClass('active');
    }else {
      $(".scroll-downs").removeClass('active');
    }
  });

  Marquee3k.init();

  var lastElement = false;
  $(".about-section-inner-3_item").each(function() {
      if (lastElement && lastElement.offset().top != $(this).offset().top) {
          $(lastElement).addClass("nobullet");
      }
      lastElement = $(this);
  }).last().addClass("nobullet");


  if( $(window).width() >= 1024)   {
    // Also can pass in optional settings block
    var rellax = new Rellax('.rellax', {
      speed: -2,
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false
    });
  }

  $(window).scroll(function() {
    if ($(this).scrollTop() > window.innerHeight ) {
      $('header li:first-child a').addClass('active');
      $('.logo').addClass('active');
      $('.logo-sub').addClass('active');
    } else {
      $('header li:first-child a').removeClass('active');
      $('.logo').removeClass('active');
      $('.logo-sub').removeClass('active');
    }
  });




  $("header li:first-child a").click(function() {
    $(".typed-wrapper-active, #typed").fadeOut();
    $(".content").fadeIn();
  })




$('.gallery__wrapper').hover(function() {
  $(".gallery__wrapper" ).addClass( "blur" );
  $(this ).removeClass( "blur" );
}, function() {
  $(".gallery__wrapper" ).removeClass( "blur" );
});


  $("a").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){
   
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
        $('.mobile-menu').removeClass("active");
        $('.mobile-trigger').removeClass("active");
      });
    } // End if
  });


  if(window.location.hash) {
    var hash = window.location.hash;
  
    $('html, body').animate({
      scrollTop: $(hash).offset().top
    }, 800, 'swing');
  }

  var image_height_scale = $('.logo').height();


    var positionY = $(window).scrollTop();

    var indicatorsmall = 50 - ((positionY / $(window).height()) * 110);
    var indicatorsmallbottom = 20 + ((positionY / $(window).height()) * 300);

            


    if (indicatorsmallbottom <= 0) {
        indicatorsmallbottom = 0
    }

    if (indicatorsmallbottom >= 100) {
        indicatorsmallbottom = 100
    }


   // $('.logo .container').css('bottom','calc(' + indicatorsmallbottom + '% - 60px)');
   // $('.logo .container').css('width', indicatorsmall + 'vw');

  $(window).scroll(function () {
    var image_height_scale = $('.logo').height();


    var positionY = $(window).scrollTop();

    var indicatorsmall = 50 - ((positionY / $(window).height()) * 110);
    var indicatorsmallbottom = 20 + ((positionY / $(window).height()) * 300);

            


    if (indicatorsmallbottom <= 0) {
        indicatorsmallbottom = 0
    }

    if (indicatorsmallbottom >= 100) {
        indicatorsmallbottom = 100
    }


  //  $('.logo .container').css('bottom','calc(' + indicatorsmallbottom + '% - 60px)');
  //  $('.logo .container').css('width', indicatorsmall + 'vw');

});




/*$(".thumbnail").click(function() {
  var title = $(this).attr('title');
  var description = $(this).attr('description');
  $(".lightbox-text h2").append(title);
  $(".lightbox-text p").append(description);
});*/


 

  $('.gallery__outer').slickLightbox({
    itemSelector: 'a.thumbnail',
    caption: false,
    lazy: true,
    captionPosition: 'bottom',
    closeOnBackdropClick: false,
    slick: {
        navigateByKeyboard: true,
        itemSelector: 'a.thumbnail',
        dots: true,
        //fade: true,
        speed: 1000,
        dotsClass: 'custom_paging',
        customPaging: function (slider, i) {
            //FYI just have a look at the object to find available information
            //press f12 to access the console in most browsers
            //you could also debug or look in the source
            console.log(slider);
            return  (i + 1) + '—' + slider.slideCount;
        },
        infinite: true,
        arrows: true,
        asNavFor: '.slider-nav',
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true,
                    draggable: true,
                    customPaging: function (slider, i) {
                      //FYI just have a look at the object to find available information
                      //press f12 to access the console in most browsers
                      //you could also debug or look in the source
                      console.log(slider);
                      return  (i + 1) + '—' + slider.slideCount;
                  },
                }
            }
        ]


    }
  }).on({
    'hide.slickLightbox': function(){ 
     /* $(".lightbox-text h2").html('');
      $(".lightbox-text p").html('');*/
      $('.slider-nav').removeClass('active');
      $('body').removeClass('locked');
    },
    'hidden.slickLightbox': function(){ 
      /* $(".lightbox-text h2").html('');
       $(".lightbox-text p").html('');*/
       $(".slick-lightbox-slick").slick('destroy');
       $(".slider-nav").slick('destroy');
     },
    'show.slickLightbox': function(){ 
    },
    'shown.slickLightbox': function(){ 
      $('body').addClass('locked');
      $('.slider-nav').addClass('active');
      $('.slider-nav').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.slick-lightbox-slick',
        dots: false,
        arrows: false,
        fade: true,
        infinite: true,
        speed: 0
      });
      var current = $(".slick-lightbox-slick").slick('slickCurrentSlide');
      $('.slider-nav .slick-slide:eq('+current+')').addClass('slick-active').siblings().removeClass('slick-active');
      $('.slider-nav .slick-slide:eq('+current+')').addClass('slick-current').siblings().removeClass('slick-current');
    },

  });







});













barba.use(css);
// init Barba
// init Barba
barba.init({
 // asych: true,
  transitions: [{
    name: 'east',
    leave() {},
    enter() {},
    from: {
      namespace: [
        'north',
        'south',
        'west',
        'home',
        'east',
        'framework',
        'essay'
      ]
    },
    to: {
      namespace: 'east'
    }
  }, 
  {
  name: 'home',
  leave() {},
  enter() {},
  from: {
    namespace: [
      'home',
      'ourstory',
      'about',
    ]
  },
  to: {
    namespace: [
      'home',
      'ourstory',
      'about',
    ]
  }
}, 

  {
    name: 'south',
    leave() {},
    enter() {},
    from: {
      namespace: [
        'north',
        'east',
        'west',
        'home',
        'framework',
        'essay'
      ]
    },
    to: {
      namespace: 'south'
    }
  },
  {
    name: 'south-inner',
    leave() {},
    enter() {},
    from: {
      namespace: [
        'south'
      ]
    },
    to: {
      namespace: 'south'
    }
  },
  {
    name: 'west',
    leave() {},
    enter() {},
    from: {
      namespace: [
        'north',
        'east',
        'south',
        'home',
        'framework',
        'west',
        'essay'
      ]
    },
    to: {
      namespace: 'west'
    }
  },
  {
    name: 'north',
    leave() {},
    enter() {},
    from: {
      namespace: [
        'east',
        'south',
        'west',
        'framework',
        'home',
        'north',
        'essay'
      ]
    },
    to: {
      namespace: 'north'
    }
  },
  {
    name: 'framework',
    leave() {},
    enter() {},
    from: {
      namespace: [
        'north',
        'framework'
      ]
    },
    to: {
      namespace: [
        'north',
        'framework'
      ]
    }
  },
  {
    name: 'essay',
    leave() {},
    enter() {},
    from: {
      namespace: [
        'west',
        'essay'
      ]
    },
    to: {
      namespace: [
        'west',
        'essay'
      ]
    }
  }
]
});

barba.hooks.beforeLeave((data) => {
  $(".desktop-nav").fadeOut();

  var o = document.getElementById('loader-1');
  o.src = o.src.replace(/(\?\d+)?$/, '?'+Date.now());

  setTimeout(function() { 
  $(".loader").addClass('active');
}, 500);
});

barba.hooks.afterLeave((data) => {
  // Set <body> classes for "next" page
  var nextHtml = data.next.html;
  var response = nextHtml.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', nextHtml)
  var bodyClasses = $(response).filter('notbody').attr('id')
  $("body").attr("id", bodyClasses);


});



barba.hooks.enter(() => {

  setTimeout(function() { 
    $(".desktop-nav").fadeIn();
  $(".loader").removeClass('active');

}, 1000);

$('.mobile-menu').removeClass("active");
$(".menu-trigger").removeClass("active");

  window.scrollTo(0, 0);



  /*$(".thumbnail").click(function() {
    var title = $(this).attr('title');
    var description = $(this).attr('description');
    $(".lightbox-text h2").append(title);
    $(".lightbox-text p").append(description);
  });*/



  $('.gallery__outer').slickLightbox({
    itemSelector: 'a.thumbnail',
    caption: false,
    lazy: true,
    captionPosition: 'bottom',
    closeOnBackdropClick: false,
    slick: {
        navigateByKeyboard: true,
        itemSelector: 'a.thumbnail',
        dots: true,
        //fade: true,
        speed: 1000,
        dotsClass: 'custom_paging',
        customPaging: function (slider, i) {
            //FYI just have a look at the object to find available information
            //press f12 to access the console in most browsers
            //you could also debug or look in the source
            console.log(slider);
            return  (i + 1) + '——' + slider.slideCount;
        },
        infinite: true,
        arrows: true,
        asNavFor: '.slider-nav',
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true,
                    draggable: true,
                    customPaging: function (slider, i) {
                      //FYI just have a look at the object to find available information
                      //press f12 to access the console in most browsers
                      //you could also debug or look in the source
                      console.log(slider);
                      return  (i + 1) + '——' + slider.slideCount;
                  },
                }
            }
        ]



    }
  }).on({
    'hide.slickLightbox': function(){ 
     /* $(".lightbox-text h2").html('');
      $(".lightbox-text p").html('');*/
      $('.slider-nav').removeClass('active');
    },
    'hidden.slickLightbox': function(){ 
      /* $(".lightbox-text h2").html('');
       $(".lightbox-text p").html('');*/
       $(".slick-lightbox-slick").slick('destroy');
       $(".slider-nav").slick('destroy');
       $('body').removeClass('locked');
     },
    'show.slickLightbox': function(){ 
    },
    'shown.slickLightbox': function(){ 
      $('.slider-nav').addClass('active');
      $('body').addClass('locked');
      $('.slider-nav').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.slick-lightbox-slick',
        dots: false,
        arrows: false,
        fade: true,
        infinite: true,
        speed: 0
      });
      var current = $(".slick-lightbox-slick").slick('slickCurrentSlide');
      $('.slider-nav .slick-slide:eq('+current+')').addClass('slick-active').siblings().removeClass('slick-active');
      $('.slider-nav .slick-slide:eq('+current+')').addClass('slick-current').siblings().removeClass('slick-current');
    },

  });

  $("a").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){
   
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });
  

  if(window.location.hash) {
    var hash = window.location.hash;
  
    $('html, body').animate({
      scrollTop: $(hash).offset().top
    }, 800, 'swing');
  }













if (!Cookies.get('north-cookie')) {
  $("#north-text").removeClass('hidden');
  $( "#north-text" ).click(function() {
    Cookies.set('north-cookie', true, { expires: 7 });
    $("#north-text").removeClass('hidden');
    $("#north-content").removeClass('hidden');
  });
}
else {
  $("#north-content").removeClass('hidden');
  $("#north-text").hide();
}


if (!Cookies.get('east-cookie')) {
  $("#east-text").removeClass('hidden');
  $( "#east-text" ).click(function() {
    Cookies.set('east-cookie', true, { expires: 7 });
    $("#east-text").removeClass('hidden');
    $("#east-content").removeClass('hidden');
  });
}
else {
  $("#east-content").removeClass('hidden');
  $("#east-text").hide();
}

if (!Cookies.get('south-cookie')) {
  $("#south-text").removeClass('hidden');
  $( "#south-text" ).click(function() {
    Cookies.set('south-cookie', true, { expires: 7 });
    $("#south-text").removeClass('hidden');
    $("#south-content").removeClass('hidden');
  });
}
else {
  $("#south-content").removeClass('hidden');
  $("#south-text").hide();
}

if (!Cookies.get('west-cookie')) {
  $("#west-text").removeClass('hidden');
  $( "#west-text" ).click(function() {
    Cookies.set('west-cookie', true, { expires: 7 });
    $("#west-text").removeClass('hidden');
    $("#west-content").removeClass('hidden');
  });
}
else {
  $("#west-content").removeClass('hidden');
  $("#west-text").hide();
}


if (!Cookies.get('mainhall-cookie')) {
  $("#mainhall-text").removeClass('hidden');
  $( "#mainhall-text" ).click(function() {
    Cookies.set('mainhall-cookie', true, { expires: 7 });
    $("#mainhall-text").removeClass('hidden');
    $("#mainhall-content").removeClass('hidden');
  });
}
else {
  $("#mainhall-content").removeClass('hidden');
  $("#mainhall-text").hide();
  $(".assistent-main").addClass("active");
  $(".north-wrapper, .east-wrapper, .west-wrapper, .south-wrapper").addClass("active");
}

if (!Cookies.get('mainhall-cookie') && window.location == "http://saschakrischock.com/kirby2/" ) {
  window.location.replace("http://saschakrischock.com/kirby2/start");
}

if (!Cookies.get('mainhall-cookie') && window.location == "http://localhost:8888/kirby2/" ) {
  window.location.replace("http://localhost:8888/kirby2/start");
}




  $(".adoby-shadow-wrapper, .about-section").click(function() {
    $(".assistent-all").toggleClass('on-top');
    $(".about-section").toggleClass('active');
  $(".notes, .game-section, .search-section, .tag-section, .assistent-text, .north-wrapper, .south-wrapper, .east-wrapper, .west-wrapper, .frameworks, .single-framework, .related-frameworks, .sheet-wrapper").toggleClass('inactive');
 })


  $("#ui_dado, .dado-mainhall").click(dado1);

        function dado1(){
          $('.notes, .assistent-all, .search-section, .tag-section').fadeOut();
          $('#ui_dado').addClass('active');
        $('.platform1, .platform2').removeClass('stop').addClass('playing');
        $('.dice1, .dice2');
        setTimeout(function(){
          $('.platform1, .platform2').removeClass('playing').addClass('stop');
                //$('#ui_dado').removeClass('active');
          var number = Math.floor(Math.random() * 6) + 1;
          var number2 = Math.floor(Math.random()/2 * 6) + 1;
           var x = 0, y = 20, z = -20;
          var x2 = 0, y2 = 20, z2 = -20;
          switch(number){
              case 1:
                x = 0; y = 20; z = -20;
                break;
              case 2:
                x = -100; y = -150; z = 10;
                break;
              case 3:
                x = 0; y = -100; z = -10;
                break;
              case 4:
                x = 0; y = 100; z = -10;
                break;
              case 5:
                x = 80; y = 120; z = -10;
                break;
              case 6:
                x = 0; y = 200; x = 10;
                break;
          }
           switch(number2){
              case 1:
                x2 = 0; y2 = 20; z2 = -20;
                break;
              case 2:
                x2 = -100; y2 = -150; z2 = 10;
                break;
              case 3:
                x2 = 0; y2 = -100; z2 = -10;
                break;
              case 4:
                x2 = 0; y2 = 100; z2 = -10;
                break;
              case 5:
                x2 = 80; y2 = 120; z2 = -10;
                break;
              case 6:
                x2 = 0; y2 = 200; x2 = 10;
                break;
          }
          
         $(".dice1").css({
            'transform': 'rotateX(' + x + 'deg) rotateY(' + y + 'deg) rotateZ(' + z + 'deg)'
             });
          
            $(".dice2").css({
            'transform': 'rotateX(' + x2 + 'deg) rotateY(' + y2 + 'deg) rotateZ(' + z2 + 'deg)'
             });
          
          $('#platform').css({
            'transform': 'translate3d(0,0, 0px)'
          });
          
          $('#result').html(number + number2);

          var urls =   new Array("https://saschakrischock.com/kirby2/east","https://saschakrischock.com/kirby2/west","https://saschakrischock.com/kirby2/north","http://saschakrischock.com/kirby2/south");
          window.location = urls[Math.floor(urls.length*Math.random())];
          var temp = setInterval("redirect()", 3000);
          
        }, 1120);
      };

      $(".dado-south").click(dadosouth);
      function dadosouth(){
        $('.notes, .assistent-all, .search-section, .tag-section').fadeOut();
        $('#ui_dado').addClass('active');
      $('.platform1, .platform2').removeClass('stop').addClass('playing');
      $('.dice1, .dice2');
      setTimeout(function(){
        $('.platform1, .platform2').removeClass('playing').addClass('stop');
              //$('#ui_dado').removeClass('active');
        var number = Math.floor(Math.random() * 6) + 1;
        var number2 = Math.floor(Math.random()/2 * 6) + 1;
         var x = 0, y = 20, z = -20;
        var x2 = 0, y2 = 20, z2 = -20;
        switch(number){
            case 1:
              x = 0; y = 20; z = -20;
              break;
            case 2:
              x = -100; y = -150; z = 10;
              break;
            case 3:
              x = 0; y = -100; z = -10;
              break;
            case 4:
              x = 0; y = 100; z = -10;
              break;
            case 5:
              x = 80; y = 120; z = -10;
              break;
            case 6:
              x = 0; y = 200; x = 10;
              break;
        }
         switch(number2){
            case 1:
              x2 = 0; y2 = 20; z2 = -20;
              break;
            case 2:
              x2 = -100; y2 = -150; z2 = 10;
              break;
            case 3:
              x2 = 0; y2 = -100; z2 = -10;
              break;
            case 4:
              x2 = 0; y2 = 100; z2 = -10;
              break;
            case 5:
              x2 = 80; y2 = 120; z2 = -10;
              break;
            case 6:
              x2 = 0; y2 = 200; x2 = 10;
              break;
        }
        
       $(".dice1").css({
          'transform': 'rotateX(' + x + 'deg) rotateY(' + y + 'deg) rotateZ(' + z + 'deg)'
           });
        
          $(".dice2").css({
          'transform': 'rotateX(' + x2 + 'deg) rotateY(' + y2 + 'deg) rotateZ(' + z2 + 'deg)'
           });
        
        $('#platform').css({
          'transform': 'translate3d(0,0, 0px)'
        });
        
        $('#result').html(number + number2);

        var urls =   new Array("https://saschakrischock.com/kirby2/south");
        window.location = urls;
        var temp = setInterval("redirect()", 3000);
        
      }, 1120);
    };

    $(".dado-east").click(dadoeast);
      
    function dadoeast(){
      $('.notes, .assistent-all, .search-section, .tag-section').fadeOut();
      $('#ui_dado').addClass('active');
    $('.platform1, .platform2').removeClass('stop').addClass('playing');
    $('.dice1, .dice2');
    setTimeout(function(){
      $('.platform1, .platform2').removeClass('playing').addClass('stop');
            //$('#ui_dado').removeClass('active');
      var number = Math.floor(Math.random() * 6) + 1;
      var number2 = Math.floor(Math.random()/2 * 6) + 1;
       var x = 0, y = 20, z = -20;
      var x2 = 0, y2 = 20, z2 = -20;
      switch(number){
          case 1:
            x = 0; y = 20; z = -20;
            break;
          case 2:
            x = -100; y = -150; z = 10;
            break;
          case 3:
            x = 0; y = -100; z = -10;
            break;
          case 4:
            x = 0; y = 100; z = -10;
            break;
          case 5:
            x = 80; y = 120; z = -10;
            break;
          case 6:
            x = 0; y = 200; x = 10;
            break;
      }
       switch(number2){
          case 1:
            x2 = 0; y2 = 20; z2 = -20;
            break;
          case 2:
            x2 = -100; y2 = -150; z2 = 10;
            break;
          case 3:
            x2 = 0; y2 = -100; z2 = -10;
            break;
          case 4:
            x2 = 0; y2 = 100; z2 = -10;
            break;
          case 5:
            x2 = 80; y2 = 120; z2 = -10;
            break;
          case 6:
            x2 = 0; y2 = 200; x2 = 10;
            break;
      }
      
     $(".dice1").css({
        'transform': 'rotateX(' + x + 'deg) rotateY(' + y + 'deg) rotateZ(' + z + 'deg)'
         });
      
        $(".dice2").css({
        'transform': 'rotateX(' + x2 + 'deg) rotateY(' + y2 + 'deg) rotateZ(' + z2 + 'deg)'
         });
      
      $('#platform').css({
        'transform': 'translate3d(0,0, 0px)'
      });
      
      $('#result').html(number + number2);

      var urls =   new Array("https://saschakrischock.com/kirby2/east");
      window.location = urls;
      var temp = setInterval("redirect()", 3000);
      
    }, 1120);
  };

  $(".dado-north").click(dadonorth);
      
  function dadonorth(){
    $('.notes, .assistent-all, .search-section, .tag-section').fadeOut();
    $('#ui_dado').addClass('active');
  $('.platform1, .platform2').removeClass('stop').addClass('playing');
  $('.dice1, .dice2');
  setTimeout(function(){
    $('.platform1, .platform2').removeClass('playing').addClass('stop');
          //$('#ui_dado').removeClass('active');
    var number = Math.floor(Math.random() * 6) + 1;
    var number2 = Math.floor(Math.random()/2 * 6) + 1;
     var x = 0, y = 20, z = -20;
    var x2 = 0, y2 = 20, z2 = -20;
    switch(number){
        case 1:
          x = 0; y = 20; z = -20;
          break;
        case 2:
          x = -100; y = -150; z = 10;
          break;
        case 3:
          x = 0; y = -100; z = -10;
          break;
        case 4:
          x = 0; y = 100; z = -10;
          break;
        case 5:
          x = 80; y = 120; z = -10;
          break;
        case 6:
          x = 0; y = 200; x = 10;
          break;
    }
     switch(number2){
        case 1:
          x2 = 0; y2 = 20; z2 = -20;
          break;
        case 2:
          x2 = -100; y2 = -150; z2 = 10;
          break;
        case 3:
          x2 = 0; y2 = -100; z2 = -10;
          break;
        case 4:
          x2 = 0; y2 = 100; z2 = -10;
          break;
        case 5:
          x2 = 80; y2 = 120; z2 = -10;
          break;
        case 6:
          x2 = 0; y2 = 200; x2 = 10;
          break;
    }
    
   $(".dice1").css({
      'transform': 'rotateX(' + x + 'deg) rotateY(' + y + 'deg) rotateZ(' + z + 'deg)'
       });
    
      $(".dice2").css({
      'transform': 'rotateX(' + x2 + 'deg) rotateY(' + y2 + 'deg) rotateZ(' + z2 + 'deg)'
       });
    
    $('#platform').css({
      'transform': 'translate3d(0,0, 0px)'
    });
    
    $('#result').html(number + number2);

    var urls =   new Array("https://saschakrischock.com/kirby2/randomizer");
    window.location = urls;
    var temp = setInterval("redirect()", 3000);
    
  }, 1120);
};


$(".dado-west").click(dadowest);
      
function dadowest(){
  $('.notes, .assistent-all, .search-section, .tag-section').fadeOut();
  $('#ui_dado').addClass('active');
$('.platform1, .platform2').removeClass('stop').addClass('playing');
$('.dice1, .dice2');
setTimeout(function(){
  $('.platform1, .platform2').removeClass('playing').addClass('stop');
        //$('#ui_dado').removeClass('active');
  var number = Math.floor(Math.random() * 6) + 1;
  var number2 = Math.floor(Math.random()/2 * 6) + 1;
   var x = 0, y = 20, z = -20;
  var x2 = 0, y2 = 20, z2 = -20;
  switch(number){
      case 1:
        x = 0; y = 20; z = -20;
        break;
      case 2:
        x = -100; y = -150; z = 10;
        break;
      case 3:
        x = 0; y = -100; z = -10;
        break;
      case 4:
        x = 0; y = 100; z = -10;
        break;
      case 5:
        x = 80; y = 120; z = -10;
        break;
      case 6:
        x = 0; y = 200; x = 10;
        break;
  }
   switch(number2){
      case 1:
        x2 = 0; y2 = 20; z2 = -20;
        break;
      case 2:
        x2 = -100; y2 = -150; z2 = 10;
        break;
      case 3:
        x2 = 0; y2 = -100; z2 = -10;
        break;
      case 4:
        x2 = 0; y2 = 100; z2 = -10;
        break;
      case 5:
        x2 = 80; y2 = 120; z2 = -10;
        break;
      case 6:
        x2 = 0; y2 = 200; x2 = 10;
        break;
  }
  
 $(".dice1").css({
    'transform': 'rotateX(' + x + 'deg) rotateY(' + y + 'deg) rotateZ(' + z + 'deg)'
     });
  
    $(".dice2").css({
    'transform': 'rotateX(' + x2 + 'deg) rotateY(' + y2 + 'deg) rotateZ(' + z2 + 'deg)'
     });
  
  $('#platform').css({
    'transform': 'translate3d(0,0, 0px)'
  });
  
  $('#result').html(number + number2);

  var urls =   new Array("https://saschakrischock.com/kirby2/west/emirhan-akin","https://saschakrischock.com/kirby2/west/andrea-gonzalez-garran","https://saschakrischock.com/kirby2/west/carmen-dusmet-carrasco","https://saschakrischock.com/kirby2/west/charlotte-rohde","https://saschakrischock.com/kirby2/west/fabian-tombers","https://saschakrischock.com/kirby2/west/francisca-khamis-giacoman","https://saschakrischock.com/kirby2/west/hanna-valle","https://saschakrischock.com/kirby2/west/levi-van-gelder","https://saschakrischock.com/kirby2/west/nicolo-pellarin","https://saschakrischock.com/kirby2/west/tali-liberman","https://saschakrischock.com/kirby2/west/wouter-stroet");
  window.location = urls[Math.floor(urls.length*Math.random())];
  var temp = setInterval("redirect()", 3000);
  
}, 1120);
};
    



$('.gallery__wrapper').hover(function() {
  $(".gallery__wrapper" ).addClass( "blur" );
  $(this ).removeClass( "blur" );
}, function() {
  $(".gallery__wrapper" ).removeClass( "blur" );
});
  

  $('.note').hover(function() {
    $("#south-page, #email-page" ).addClass( "black" );
  }, function() {
    $( "#south-page, #email-page" ).removeClass( "black" );
  });

     
  function sheet1() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1ilWbc-yqheteAG-YV4ixbkSa3iyccWtAOSQNPJene3I/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-library").append(
          ' <div class="tRow"><div class="tCell"><span>' +
                      this.gsx$givenby.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$suggestedby.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$title.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$author.$t +
            "</div></div>"
        );
      });
    });
  }

  function sheet2() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1QU37A9ytP7hHjOGfR36tdV1owD_zmdL9I6k1OeMqtYI/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-rob").append(
          ' <div class="tRow"><div class="tCell"><span>' +
                      this.gsx$authors.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$booktitle.$t +
            "</div></div>"
        );
      });
    });
  }

  function sheet3() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1ezbq_shGGunlipm1qSWCWDMA-XSp2xvWjQp9nkRvxxg/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-wishlist").append(
          ' <div class="tRow"><div class="tCell"><span>' +
                      this.gsx$title.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$author.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$from.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$pdf.$t +
            "</div></div>"
        );
      });
    });
  }
  
  function sheet4() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1_TaaZAOGjlRF-S6w9R-z4xCcpV1jkeLLdtSBhyFkV-c/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-conferences").append(
          ' <div class="tRow"><div class="tCell"><span>' +
                      this.gsx$conference.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$date.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$signup.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$link.$t +
            "</div></div>"
        );
      });
    });
  }

  function sheet5() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1k3yLskgka-4jDOuRo6Vr4OldeqeyYQsxuzfBZJlU2j8/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-collectives").append(
          ' <div class="tRow"><div class="tCell"><span>' +
            this.gsx$collective.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$student.$t +
            "</div></div>"
        );
      });
    });
  }


  function sheet6() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1_2suzBjRERLSQhr94xdvvsEtTQJfUuKaGjroePpOudw/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-cleaning").append(
          ' <div class="tRow"><div class="tCell"><span>' +
                      this.gsx$week.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$responsible.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$weeklycleaningrequires.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$monthlycleaningrequires.$t +
            "</div></div>"
        );
      });
    });
  }




  sheet1();
  sheet2();
  sheet3();
  sheet4();
  sheet5();
  sheet6();

  var isSafari = window.safari !== undefined;
  if (isSafari) {
    $(".adoby-animation").hide();
    $(".safari-animation").show();
  }



  






  var Books = (function() {

    transEndEventName = 'transitionend webkitTransitionEnd oTransitionEnd',
    $books = $( '#bk-list > li > div.bk-book' ), booksCount = $books.length, currentbook = -1; 
  

    $books.each( function( i ) {
      
      var $book = $( this ),
        $other = $books.not( $book ),
        $parent = $book.parent(),
        $page = $book.children( 'div.bk-page' ),
        $content = $page.children( 'div.bk-content' ), current = 0;

      if( i < booksCount / 2 ) {
        $parent.css( 'z-index', i ).data( 'stackval', i );
      }
      else {
        $parent.css( 'z-index', booksCount - 1 - i ).data( 'stackval', booksCount - 1 - i );	
      }

      $book.on( 'click', function() {
        
        if( currentbook !== -1 && currentbook !== $parent.index() ) {
          closeCurrent();
        }
        
        if( $book.data( 'opened' ) ) {
          $book.data( 'opened', false ).removeClass( 'bk-viewinside' ).on( transEndEventName, function() {
            $( this ).off( transEndEventName ).removeClass( 'bk-outside' );
            $parent.css( 'z-index', $parent.data( 'stackval' ) );
            currentbook = -1;
          } );
        }
        else {
          $book.data( 'opened', true ).addClass( 'bk-outside' ).on( transEndEventName, function() {
            $( this ).off( transEndEventName ).addClass( 'bk-viewinside' );
            $parent.css( 'z-index', booksCount );
            currentbook = $parent.index();
          } );
          current = 0;
          $content.removeClass( 'bk-content-current' ).eq( current ).addClass( 'bk-content-current' );
        }

      } );

      if( $content.length > 1 ) {

       /* var $navPrev = $( '<span class="bk-page-prev">&lt;</span>' ),
          $navNext = $( '<span class="bk-page-next">&gt;</span>' );
        
        $page.append( $( '<nav></nav>' ).append( $navPrev, $navNext ) ); 

        $navPrev.on( 'click', function() {
          if( current > 0 ) {
            --current;
            $content.removeClass( 'bk-content-current' ).eq( current ).addClass( 'bk-content-current' );
          }
          return false;
        } );

        $navNext.on( 'click', function() {
          if( current < $content.length - 1 ) {
            ++current;
            $content.removeClass( 'bk-content-current' ).eq( current ).addClass( 'bk-content-current' );
          }
          return false;
        } );
        */

      }
      
    } );


  function closeCurrent() {

    var $book = $books.eq( currentbook ),
      $parent = $book.parent();
    
    $book.data( 'opened', false ).removeClass( 'bk-viewinside' ).on( transEndEventName, function(e) {
      $( this ).off( transEndEventName ).removeClass( 'bk-outside' );
      $parent.css( 'z-index', $parent.data( 'stackval' ) );
    } );

  }



})();


});

/*barba.init({
  transitions: [{
    name: 'opacity-transition',
    async: true,
    leave(data) {
     $(data.current.container).addClass("test");
    },
    enter(data) {
      setTimeout(function() { 
      $(data.next.container).addClass("test-2");
    }, 5000);

    }
  }]
});
*/



  /*Barba.Dispatcher.on('transitionCompleted', function (container) {
    alert("test");
  });

  Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, container) {
    alert("test");
    });*/








$(document).ready(function() {

 
  $(".menu-trigger").click(function() {
    $(this).toggleClass("active");
    $('.logo').toggleClass("active-blur");
    $('#home .logo-sub').toggleClass("active-top");
    $(".mobile-menu").toggleClass("active");
    $(".home__header").toggleClass("active");
    $(".scroll-downs").toggleClass("active");
    $("body").toggleClass("locked");
    $(".gallery__outer").toggleClass("active");
  })





  // save name
const input = document.querySelector('[data-input]')
const btn = document.querySelector('[data-btn]')
const name = document.querySelector('[data-name]')
const fetched_name  = localStorage.getItem('name')
$('#name').text(localStorage.getItem('name'));

$('#fname').keydown(function(event) {
  // enter has keyCode = 13, change it if you want to use another button
  if (event.keyCode == 13) {
    $('.href').click()
    return false;
  }
});

$(".href").click(function() {
	localStorage.setItem('name', input.value);

})


  
  
  
    $(".typed-wrapper-home").click(function() {
      $(".typed-wrapper-home, #typed").fadeOut();
      $(".name-form, .assistent-name").fadeIn();
    })
  




  if (!Cookies.get('north-cookie')) {
    $("#north-text").removeClass('hidden');
    $( "#north-text" ).click(function() {
      Cookies.set('north-cookie', true, { expires: 7 });
      $("#north-text").removeClass('hidden');
      $("#north-content").removeClass('hidden');
    });
  }
  else {
    $("#north-text").addClass('hidden');
    $("#north-content").removeClass('hidden');
  }

  if (!Cookies.get('east-cookie')) {
    $("#east-text").removeClass('hidden');
    $( "#east-text" ).click(function() {
      Cookies.set('east-cookie', true, { expires: 7 });
      $("#east-text").removeClass('hidden');
      $("#east-content").removeClass('hidden');
    });
  }
  else {
    $("#east-content").removeClass('hidden');
    $("#east-text").hide();
  }
  
  if (!Cookies.get('south-cookie')) {
    $("#south-text").removeClass('hidden');
    $( "#south-text" ).click(function() {
      Cookies.set('south-cookie', true, { expires: 7 });
      $("#south-text").removeClass('hidden');
      $("#south-content").removeClass('hidden');
    });
  }
  else {
    $("#south-content").removeClass('hidden');
    $("#south-text").hide();
  }
  
  if (!Cookies.get('west-cookie')) {
    $("#west-text").removeClass('hidden');
    $( "#west-text" ).click(function() {
      Cookies.set('west-cookie', true, { expires: 7 });
      $("#west-text").removeClass('hidden');
      $("#west-content").removeClass('hidden');
    });
  }
  else {
    $("#west-content").removeClass('hidden');
    $("#west-text").hide();
  }

  if (!Cookies.get('mainhall-cookie')) {
    $("#mainhall-text").removeClass('hidden');
    $( "#mainhall-text" ).click(function() {
      Cookies.set('mainhall-cookie', true, { expires: 7 });
      $("#mainhall-text").removeClass('hidden');
      $("#mainhall-content").removeClass('hidden');
    });
  }
  else {
    $("#mainhall-content").removeClass('hidden');
    $("#mainhall-text").hide();
    $(".assistent-main").addClass("active");
    $(".north-wrapper, .east-wrapper, .west-wrapper, .south-wrapper").addClass("active");
  }

  if (!Cookies.get('mainhall-cookie') && window.location == "http://saschakrischock.com/kirby2/" ) {
    window.location.replace("http://saschakrischock.com/kirby2/start");
}


if (!Cookies.get('mainhall-cookie') && window.location == "https://saschakrischock.com/kirby2/" ) {
  window.location.replace("http://saschakrischock.com/kirby2/start");
}


  if (!Cookies.get('mainhall-cookie') && window.location == "http://localhost:8888/kirby2/" ) {
    window.location.replace("http://localhost:8888/kirby2/start");
}

  


/*
var easthtext = document.getElementById('north-text');
var eastcontent = document.getElementById('north-content');

if (!Cookies.get('north-cookie')) {
northtext.classList.remove('hidden');
northtext.onclick = function() {
  Cookies.set('north-cookie', true, { expires: 7 });
  northtext.classList.add('hidden');
  northcontent.classList.remove('hidden');
}
}
else {
$("#north-content").removeClass('hidden');
}
*/


  $('.adoby-wrapper-1 .adoby-animation').on('animationend webkitAnimationEnd', function() {
    $('.adoby-wrapper-1 .adoby-animation').removeClass("active");
     $('.adoby-wrapper-1 .adoby-animation-1-0').addClass("active");
        setTimeout(function() { 
          var $li = $('.adoby-wrapper-1 .adoby-animation');
      $li.eq(Math.floor(Math.random()*9)).addClass("active");
               $('.adoby-wrapper-1 .adoby-animation-1-0').removeClass("active");
          }, 1000);
  });


  $('.adoby-wrapper-2 .adoby-animation').on('animationend webkitAnimationEnd', function() {
    $('.adoby-wrapper-2 .adoby-animation').removeClass("active");
     $('.adoby-wrapper-2 .adoby-animation-2-0').addClass("active");
        setTimeout(function() { 
          var $li = $('.adoby-wrapper-2 .adoby-animation');
      $li.eq(Math.floor(Math.random()*5)).addClass("active");
               $('.adoby-wrapper-2 .adoby-animation-2-0').removeClass("active");
          }, 1000);
  });

  $('.adoby-wrapper-3 .adoby-animation').on('animationend webkitAnimationEnd', function() {
    $('.adoby-wrapper-3 .adoby-animation').removeClass("active");
     $('.adoby-wrapper-3 .adoby-animation-3-0').addClass("active");
        setTimeout(function() { 
          var $li = $('.adoby-wrapper-3 .adoby-animation');
      $li.eq(Math.floor(Math.random()*4)).addClass("active");
               $('.adoby-wrapper-3 .adoby-animation-3-0').removeClass("active");
          }, 1000);
  });

  $('.adoby-wrapper-4 .adoby-animation').on('animationend webkitAnimationEnd', function() {
    $('.adoby-wrapper-4 .adoby-animation').removeClass("active");
     $('.adoby-wrapper-4 .adoby-animation-4-0').addClass("active");
        setTimeout(function() { 
          var $li = $('.adoby-wrapper-4 .adoby-animation');
      $li.eq(Math.floor(Math.random()*4)).addClass("active");
               $('.adoby-wrapper-4 .adoby-animation-4-0').removeClass("active");
          }, 1000);
  });

  $('.adoby-wrapper-5 .adoby-animation').on('animationend webkitAnimationEnd', function() {
    $('.adoby-wrapper-5 .adoby-animation').removeClass("active");
     $('.adoby-wrapper-5 .adoby-animation-5-0').addClass("active");
        setTimeout(function() { 
          var $li = $('.adoby-wrapper-5 .adoby-animation');
      $li.eq(Math.floor(Math.random()*7)).addClass("active");
               $('.adoby-wrapper-5 .adoby-animation-5-0').removeClass("active");
          }, 1000);
  });

  $('.adoby-wrapper-6 .adoby-animation').on('animationend webkitAnimationEnd', function() {
    $('.adoby-wrapper-6 .adoby-animation').removeClass("active");
     $('.adoby-wrapper-6 .adoby-animation-6-0').addClass("active");
        setTimeout(function() { 
          var $li = $('.adoby-wrapper-6 .adoby-animation');
      $li.eq(Math.floor(Math.random()*7)).addClass("active");
               $('.adoby-wrapper-6 .adoby-animation-6-0').removeClass("active");
          }, 1000);
  });
  

  $(".adoby-shadow-wrapper, .about-section").click(function() {
    $(".assistent-all").toggleClass('on-top');
    $(".about-section").toggleClass('active');
  $(".notes, .game-section, .search-section, .tag-section, .assistent-text, .north-wrapper, .south-wrapper, .east-wrapper, .west-wrapper, .frameworks, .single-framework, .related-frameworks, .sheet-wrapper").toggleClass('inactive');
 })





   


      $(".typed-wrapper-active").click(function() {
        $(".typed-wrapper-active, #typed").fadeOut();
        $(".content").fadeIn();
      })




        $('.note').hover(function() {
            $("#south-page, #email-page" ).addClass( "black" );
        }, function() {
            $( "#south-page, #email-page" ).removeClass( "black" );
        });
   
    
        $("#ui_dado").click(dado1);

      

        function dado1(){
          $('.notes, .search-section, .tag-section').fadeOut();
          $('#ui_dado').addClass('active');
        $('.platform1, .platform2').removeClass('stop').addClass('playing');
        $('.dice1, .dice2');
        setTimeout(function(){
          $('.platform1, .platform2').removeClass('playing').addClass('stop');
                //$('#ui_dado').removeClass('active');
          var number = Math.floor(Math.random() * 6) + 1;
          var number2 = Math.floor(Math.random()/2 * 6) + 1;
           var x = 0, y = 20, z = -20;
          var x2 = 0, y2 = 20, z2 = -20;
          switch(number){
              case 1:
                x = 0; y = 20; z = -20;
                break;
              case 2:
                x = -100; y = -150; z = 10;
                break;
              case 3:
                x = 0; y = -100; z = -10;
                break;
              case 4:
                x = 0; y = 100; z = -10;
                break;
              case 5:
                x = 80; y = 120; z = -10;
                break;
              case 6:
                x = 0; y = 200; x = 10;
                break;
          }
           switch(number2){
              case 1:
                x2 = 0; y2 = 20; z2 = -20;
                break;
              case 2:
                x2 = -100; y2 = -150; z2 = 10;
                break;
              case 3:
                x2 = 0; y2 = -100; z2 = -10;
                break;
              case 4:
                x2 = 0; y2 = 100; z2 = -10;
                break;
              case 5:
                x2 = 80; y2 = 120; z2 = -10;
                break;
              case 6:
                x2 = 0; y2 = 200; x2 = 10;
                break;
          }
          
         $(".dice1").css({
            'transform': 'rotateX(' + x + 'deg) rotateY(' + y + 'deg) rotateZ(' + z + 'deg)'
             });
          
            $(".dice2").css({
            'transform': 'rotateX(' + x2 + 'deg) rotateY(' + y2 + 'deg) rotateZ(' + z2 + 'deg)'
             });
          
          $('#platform').css({
            'transform': 'translate3d(0,0, 0px)'
          });
          
          $('#result').html(number + number2);

          var urls = new Array("https://saschakrischock.com/kirby2/east","https://saschakrischock.com/kirby2/west","https://saschakrischock.com/kirby2/north");
          window.location = urls[Math.floor(urls.length*Math.random())];
          var temp = setInterval("redirect()", 3000);
        
        }, 1120);
      };


      $(".dado-south").click(dadosouth);
      
            function dadosouth(){
              $('.notes, .assistent-all, .search-section, .tag-section').fadeOut();
              $('#ui_dado').addClass('active');
            $('.platform1, .platform2').removeClass('stop').addClass('playing');
            $('.dice1, .dice2');
            setTimeout(function(){
              $('.platform1, .platform2').removeClass('playing').addClass('stop');
                    //$('#ui_dado').removeClass('active');
              var number = Math.floor(Math.random() * 6) + 1;
              var number2 = Math.floor(Math.random()/2 * 6) + 1;
               var x = 0, y = 20, z = -20;
              var x2 = 0, y2 = 20, z2 = -20;
              switch(number){
                  case 1:
                    x = 0; y = 20; z = -20;
                    break;
                  case 2:
                    x = -100; y = -150; z = 10;
                    break;
                  case 3:
                    x = 0; y = -100; z = -10;
                    break;
                  case 4:
                    x = 0; y = 100; z = -10;
                    break;
                  case 5:
                    x = 80; y = 120; z = -10;
                    break;
                  case 6:
                    x = 0; y = 200; x = 10;
                    break;
              }
               switch(number2){
                  case 1:
                    x2 = 0; y2 = 20; z2 = -20;
                    break;
                  case 2:
                    x2 = -100; y2 = -150; z2 = 10;
                    break;
                  case 3:
                    x2 = 0; y2 = -100; z2 = -10;
                    break;
                  case 4:
                    x2 = 0; y2 = 100; z2 = -10;
                    break;
                  case 5:
                    x2 = 80; y2 = 120; z2 = -10;
                    break;
                  case 6:
                    x2 = 0; y2 = 200; x2 = 10;
                    break;
              }
              
             $(".dice1").css({
                'transform': 'rotateX(' + x + 'deg) rotateY(' + y + 'deg) rotateZ(' + z + 'deg)'
                 });
              
                $(".dice2").css({
                'transform': 'rotateX(' + x2 + 'deg) rotateY(' + y2 + 'deg) rotateZ(' + z2 + 'deg)'
                 });
              
              $('#platform').css({
                'transform': 'translate3d(0,0, 0px)'
              });
              
              $('#result').html(number + number2);
      
              var urls =   new Array("https://saschakrischock.com/kirby2/south");
              window.location = urls;
              var temp = setInterval("redirect()", 3000);
              
            }, 1120);
          };

          $(".dado-east").click(dadoeast);
      
            function dadoeast(){
              $('.notes, .assistent-all, .search-section, .tag-section').fadeOut();
              $('#ui_dado').addClass('active');
            $('.platform1, .platform2').removeClass('stop').addClass('playing');
            $('.dice1, .dice2');
            setTimeout(function(){
              $('.platform1, .platform2').removeClass('playing').addClass('stop');
                    //$('#ui_dado').removeClass('active');
              var number = Math.floor(Math.random() * 6) + 1;
              var number2 = Math.floor(Math.random()/2 * 6) + 1;
               var x = 0, y = 20, z = -20;
              var x2 = 0, y2 = 20, z2 = -20;
              switch(number){
                  case 1:
                    x = 0; y = 20; z = -20;
                    break;
                  case 2:
                    x = -100; y = -150; z = 10;
                    break;
                  case 3:
                    x = 0; y = -100; z = -10;
                    break;
                  case 4:
                    x = 0; y = 100; z = -10;
                    break;
                  case 5:
                    x = 80; y = 120; z = -10;
                    break;
                  case 6:
                    x = 0; y = 200; x = 10;
                    break;
              }
               switch(number2){
                  case 1:
                    x2 = 0; y2 = 20; z2 = -20;
                    break;
                  case 2:
                    x2 = -100; y2 = -150; z2 = 10;
                    break;
                  case 3:
                    x2 = 0; y2 = -100; z2 = -10;
                    break;
                  case 4:
                    x2 = 0; y2 = 100; z2 = -10;
                    break;
                  case 5:
                    x2 = 80; y2 = 120; z2 = -10;
                    break;
                  case 6:
                    x2 = 0; y2 = 200; x2 = 10;
                    break;
              }
              
             $(".dice1").css({
                'transform': 'rotateX(' + x + 'deg) rotateY(' + y + 'deg) rotateZ(' + z + 'deg)'
                 });
              
                $(".dice2").css({
                'transform': 'rotateX(' + x2 + 'deg) rotateY(' + y2 + 'deg) rotateZ(' + z2 + 'deg)'
                 });
              
              $('#platform').css({
                'transform': 'translate3d(0,0, 0px)'
              });
              
              $('#result').html(number + number2);
      
              var urls =   new Array("https://saschakrischock.com/kirby2/east");
              window.location = urls;
              var temp = setInterval("redirect()", 3000);
              
            }, 1120);
          };

          $(".dado-west").click(dadowest);
      
            function dadowest(){
              $('.notes, .assistent-all, .search-section, .tag-section').fadeOut();
              $('#ui_dado').addClass('active');
            $('.platform1, .platform2').removeClass('stop').addClass('playing');
            $('.dice1, .dice2');
            setTimeout(function(){
              $('.platform1, .platform2').removeClass('playing').addClass('stop');
                    //$('#ui_dado').removeClass('active');
              var number = Math.floor(Math.random() * 6) + 1;
              var number2 = Math.floor(Math.random()/2 * 6) + 1;
               var x = 0, y = 20, z = -20;
              var x2 = 0, y2 = 20, z2 = -20;
              switch(number){
                  case 1:
                    x = 0; y = 20; z = -20;
                    break;
                  case 2:
                    x = -100; y = -150; z = 10;
                    break;
                  case 3:
                    x = 0; y = -100; z = -10;
                    break;
                  case 4:
                    x = 0; y = 100; z = -10;
                    break;
                  case 5:
                    x = 80; y = 120; z = -10;
                    break;
                  case 6:
                    x = 0; y = 200; x = 10;
                    break;
              }
               switch(number2){
                  case 1:
                    x2 = 0; y2 = 20; z2 = -20;
                    break;
                  case 2:
                    x2 = -100; y2 = -150; z2 = 10;
                    break;
                  case 3:
                    x2 = 0; y2 = -100; z2 = -10;
                    break;
                  case 4:
                    x2 = 0; y2 = 100; z2 = -10;
                    break;
                  case 5:
                    x2 = 80; y2 = 120; z2 = -10;
                    break;
                  case 6:
                    x2 = 0; y2 = 200; x2 = 10;
                    break;
              }
              
             $(".dice1").css({
                'transform': 'rotateX(' + x + 'deg) rotateY(' + y + 'deg) rotateZ(' + z + 'deg)'
                 });
              
                $(".dice2").css({
                'transform': 'rotateX(' + x2 + 'deg) rotateY(' + y2 + 'deg) rotateZ(' + z2 + 'deg)'
                 });
              
              $('#platform').css({
                'transform': 'translate3d(0,0, 0px)'
              });
              
              $('#result').html(number + number2);
      
              var urls =   new Array("https://saschakrischock.com/kirby2/west/emirhan-akin","https://saschakrischock.com/kirby2/west/andrea-gonzalez-garran","https://saschakrischock.com/kirby2/west/carmen-dusmet-carrasco","https://saschakrischock.com/kirby2/west/charlotte-rohde","https://saschakrischock.com/kirby2/west/fabian-tombers","https://saschakrischock.com/kirby2/west/francisca-khamis-giacoman","https://saschakrischock.com/kirby2/west/hanna-valle","https://saschakrischock.com/kirby2/west/levi-van-gelder","https://saschakrischock.com/kirby2/west/nicolo-pellarin","https://saschakrischock.com/kirby2/west/tali-liberman","https://saschakrischock.com/kirby2/west/wouter-stroet");
              window.location = urls[Math.floor(urls.length*Math.random())];
              var temp = setInterval("redirect()", 3000);
              
            }, 1120);
          };

          $(".dado-north").click(dadonorth);
      
          function dadonorth(){
            $('.notes, .assistent-all, .search-section, .tag-section').fadeOut();
            $('#ui_dado').addClass('active');
          $('.platform1, .platform2').removeClass('stop').addClass('playing');
          $('.dice1, .dice2');
          setTimeout(function(){
            $('.platform1, .platform2').removeClass('playing').addClass('stop');
                  //$('#ui_dado').removeClass('active');
            var number = Math.floor(Math.random() * 6) + 1;
            var number2 = Math.floor(Math.random()/2 * 6) + 1;
             var x = 0, y = 20, z = -20;
            var x2 = 0, y2 = 20, z2 = -20;
            switch(number){
                case 1:
                  x = 0; y = 20; z = -20;
                  break;
                case 2:
                  x = -100; y = -150; z = 10;
                  break;
                case 3:
                  x = 0; y = -100; z = -10;
                  break;
                case 4:
                  x = 0; y = 100; z = -10;
                  break;
                case 5:
                  x = 80; y = 120; z = -10;
                  break;
                case 6:
                  x = 0; y = 200; x = 10;
                  break;
            }
             switch(number2){
                case 1:
                  x2 = 0; y2 = 20; z2 = -20;
                  break;
                case 2:
                  x2 = -100; y2 = -150; z2 = 10;
                  break;
                case 3:
                  x2 = 0; y2 = -100; z2 = -10;
                  break;
                case 4:
                  x2 = 0; y2 = 100; z2 = -10;
                  break;
                case 5:
                  x2 = 80; y2 = 120; z2 = -10;
                  break;
                case 6:
                  x2 = 0; y2 = 200; x2 = 10;
                  break;
            }
            
           $(".dice1").css({
              'transform': 'rotateX(' + x + 'deg) rotateY(' + y + 'deg) rotateZ(' + z + 'deg)'
               });
            
              $(".dice2").css({
              'transform': 'rotateX(' + x2 + 'deg) rotateY(' + y2 + 'deg) rotateZ(' + z2 + 'deg)'
               });
            
            $('#platform').css({
              'transform': 'translate3d(0,0, 0px)'
            });
            
            $('#result').html(number + number2);
    
            var urls =   new Array("https://saschakrischock.com/kirby2/randomizer");
            window.location = urls;
            var temp = setInterval("redirect()", 3000);
            
          }, 1120);
        };

    //make a variable to place our http request to google sheets api
    
    function sheet1() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1ilWbc-yqheteAG-YV4ixbkSa3iyccWtAOSQNPJene3I/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-library").append(
          ' <div class="tRow"><div class="tCell"><span>' +
                      this.gsx$givenby.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$suggestedby.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$title.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$author.$t +
            "</div></div>"
        );
      });
    });
  }

  function sheet2() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1QU37A9ytP7hHjOGfR36tdV1owD_zmdL9I6k1OeMqtYI/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-rob").append(
          ' <div class="tRow"><div class="tCell"><span>' +
            this.gsx$authors.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$booktitle.$t +
            "</div></div>"
        );
      });
    });
  }

  function sheet3() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1ezbq_shGGunlipm1qSWCWDMA-XSp2xvWjQp9nkRvxxg/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-wishlist").append(
          ' <div class="tRow"><div class="tCell"><span>' +
            this.gsx$title.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$author.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$from.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$pdf.$t +
            "</div></div>"
        );
      });
    });
  }

  function sheet4() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1_TaaZAOGjlRF-S6w9R-z4xCcpV1jkeLLdtSBhyFkV-c/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-conferences").append(
          ' <div class="tRow"><div class="tCell"><span>' +
                      this.gsx$conference.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$date.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$signup.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$link.$t +
            "</div></div>"
        );
      });
    });
  }

  function sheet5() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1k3yLskgka-4jDOuRo6Vr4OldeqeyYQsxuzfBZJlU2j8/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-collectives").append(
          ' <div class="tRow"><div class="tCell"><span>' +
            this.gsx$collective.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$student.$t +
            "</div></div>"
        );
      });
    });
  }

  function sheet6() {
    var sheetURL =
      "https://spreadsheets.google.com/feeds/list/1_2suzBjRERLSQhr94xdvvsEtTQJfUuKaGjroePpOudw/od6/public/values?alt=json";
    
    $.getJSON(sheetURL, function(data) {
      var headInfo = data.feed.title.$t;
    
      $("#heading").html(headInfo);
    
      var entryData = data.feed.entry;
    
      console.log(data);
    
      jQuery.each(entryData, function() {
        $("#sheet-cleaning").append(
          ' <div class="tRow"><div class="tCell"><span>' +
                      this.gsx$week.$t +
            '</span></div><div class="tCell"><span>' +
            this.gsx$responsible.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$weeklycleaningrequires.$t +
            '</div><div class="tCell"><span>' +
            this.gsx$monthlycleaningrequires.$t +
            "</div></div>"
        );
      });
    });
  }



      

  sheet1();
  sheet2();
  sheet3();
  sheet4();
  sheet5();
  sheet6();



  var Books = (function() {

      transEndEventName = 'transitionend webkitTransitionEnd oTransitionEnd',
      $books = $( '#bk-list > li > div.bk-book' ), booksCount = $books.length, currentbook = -1; 
    
  
      $books.each( function( i ) {
        
        var $book = $( this ),
          $other = $books.not( $book ),
          $parent = $book.parent(),
          $page = $book.children( 'div.bk-page' ),
          $content = $page.children( 'div.bk-content' ), current = 0;
  
        if( i < booksCount / 2 ) {
          $parent.css( 'z-index', i ).data( 'stackval', i );
        }
        else {
          $parent.css( 'z-index', booksCount - 1 - i ).data( 'stackval', booksCount - 1 - i );	
        }
  
        $book.on( 'click', function() {
          
          if( currentbook !== -1 && currentbook !== $parent.index() ) {
            closeCurrent();
          }
          
          if( $book.data( 'opened' ) ) {
            $book.data( 'opened', false ).removeClass( 'bk-viewinside' ).on( transEndEventName, function() {
              $( this ).off( transEndEventName ).removeClass( 'bk-outside' );
              $parent.css( 'z-index', $parent.data( 'stackval' ) );
              currentbook = -1;
            } );
          }
          else {
            $book.data( 'opened', true ).addClass( 'bk-outside' ).on( transEndEventName, function() {
              $( this ).off( transEndEventName ).addClass( 'bk-viewinside' );
              $parent.css( 'z-index', booksCount );
              currentbook = $parent.index();
            } );
            current = 0;
            $content.removeClass( 'bk-content-current' ).eq( current ).addClass( 'bk-content-current' );
          }
  
        } );
  
        if( $content.length > 1 ) {
  
         /* var $navPrev = $( '<span class="bk-page-prev">&lt;</span>' ),
            $navNext = $( '<span class="bk-page-next">&gt;</span>' );
          
          $page.append( $( '<nav></nav>' ).append( $navPrev, $navNext ) ); 
  
          $navPrev.on( 'click', function() {
            if( current > 0 ) {
              --current;
              $content.removeClass( 'bk-content-current' ).eq( current ).addClass( 'bk-content-current' );
            }
            return false;
          } );
  
          $navNext.on( 'click', function() {
            if( current < $content.length - 1 ) {
              ++current;
              $content.removeClass( 'bk-content-current' ).eq( current ).addClass( 'bk-content-current' );
            }
            return false;
          } );
          */
  
        }
        
      } );
  
  
    function closeCurrent() {
  
      var $book = $books.eq( currentbook ),
        $parent = $book.parent();
      
      $book.data( 'opened', false ).removeClass( 'bk-viewinside' ).on( transEndEventName, function(e) {
        $( this ).off( transEndEventName ).removeClass( 'bk-outside' );
        $parent.css( 'z-index', $parent.data( 'stackval' ) );
      } );
  
    }
  
  
  })();
  

  
  var isSafari = window.safari !== undefined;
  if (isSafari) {
    $(".adoby-animation").hide();
    $(".safari-animation").show();
  }

    });











